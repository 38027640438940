import { useTranslation } from "react-i18next";
import Button from "../../atoms/Button";
import { InLove, Mentor, Rocket } from "../../atoms/Icon/Icon";
import styles from "./OurObjectives.module.scss";
import { routes } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";

export interface OurObjectivesProps {
  className?: string;
  open_modal?: () => void;
}

export const OurObjectives = ({
  className = "",
  open_modal,
}: OurObjectivesProps) => {
  const { t } = useTranslation("HOME");
  const navigate = useNavigate();
  return (
    <section className={[styles.iNostriObiettiviParent, className].join(" ")}>
      <h1 className={styles.iNostriObiettivi}>{t("OBJECTIVE.TITLE")}</h1>
      <div className={styles.objectiveWrapper}>
        <div className={styles.objective}>
          <div className={styles.iconContainer}>
            <Rocket />
          </div>
          <div className={styles.objectiveText}>
            <h2>{t("OBJECTIVE.FIRST_CARD.TITLE")}</h2>
            <p>{t("OBJECTIVE.FIRST_CARD.DESCRIPTION")}</p>
          </div>
        </div>
        <div className={styles.objective}>
          <div className={styles.iconContainer}>
            <Mentor />
          </div>
          <div className={styles.objectiveText}>
            <h2>{t("OBJECTIVE.SECOND_CARD.TITLE")}</h2>
            <p>{t("OBJECTIVE.SECOND_CARD.DESCRIPTION")}</p>
          </div>
        </div>
        <div className={styles.objective}>
          <div className={styles.iconContainer}>
            <InLove />
          </div>
          <div className={styles.objectiveText}>
            <h2>{t("OBJECTIVE.THIRD_CARD.TITLE")}</h2>
            <p>{t("OBJECTIVE.THIRD_CARD.DESCRIPTION")}</p>
          </div>
        </div>
      </div>
      <Button
        className={styles.ourObjectivesBtn}
        onClick={() => {
          navigate(routes.surveyIntro);
        }}
      >
        <b>{t("BUTTON.NEW_IDEA")}</b>
      </Button>
    </section>
  );
};
