import Button from "../../atoms/Button";
import styles from "./ImageCardSection.module.scss";

export interface ImageCardSectionProps {
  className?: string;
  image: string;
  title: string;
  description: React.ReactNode;
  cta_text: string;
  cta_action?: () => void;
  variant?: "light" | "dark";
  anchor?: string;
}

export const ImageCardSection = ({
  className = "",
  cta_action,
  cta_text,
  description,
  image,
  title,
  variant,
  anchor,
}: ImageCardSectionProps) => {
  return (
    <section
      className={[
        styles.proposedIdeasContentWrapper,
        className,
        variant && styles[variant],
      ].join(" ")}
    >
      <div className={styles.proposedIdeasContent}>
        <img
          className={styles.proposedIdeasContentChild}
          loading="lazy"
          alt=""
          srcSet={image}
        />
        <div className={styles.box}>
          <div className={styles.box1}>
            <div className={styles.ideeInnovativeProposteParent}>
              <h1 className={styles.ideeInnovativeProposte}>{title}</h1>
              <div className={styles.vuoiSaperneDi}>{description}</div>
            </div>
            <Button contrast={variant === "dark"} onClick={cta_action}>
              <b>{cta_text}</b>
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
