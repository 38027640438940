import styles from "./Tab.module.scss";

interface TabProps {
  className?: string;
  active?: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const Tab = ({ active, className, children, onClick }: TabProps) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.tab} ${className} ${active && styles.active}`}
    >
      {children}
    </div>
  );
};
