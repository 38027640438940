import styles from "./Chip.module.scss";

interface ChipProps {
  className?: string;
  children: string;
}

export const Chip = ({ className, children }: ChipProps) => {
  return <div className={`${styles.chip} ${className}`}>{children}</div>;
};
