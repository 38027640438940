import styles from "./Checkbox.module.scss";
import {
  Checkbox as AntCheckbox,
  CheckboxProps as AntCheckboxProps,
} from "antd";

interface CheckboxProps extends AntCheckboxProps {
  className?: string;
  input_shape?: "circle" | "square";
}

export const Checkbox = ({
  className,
  input_shape = "square",
  ...props
}: CheckboxProps) => {
  return (
    <div>
      <AntCheckbox
        className={`${styles.checkbox} ${className} ${styles[input_shape]}`}
        {...props}
      />
    </div>
  );
};
