import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import styles from "./Button.module.scss";

interface ButtonProps extends AntButtonProps {
  className?: string;
  variant?: "primary" | "secondary" | "text";
  left_icon?: React.ReactNode;
  right_icon?: React.ReactNode;
  contrast?: boolean;
}

export const Button = ({
  className,
  children,
  variant = "primary",
  contrast,
  left_icon,
  right_icon,
  ...props
}: ButtonProps) => {
  return (
    <AntButton
      {...props}
      className={`${styles.button} ${className} ${styles[variant]} ${
        contrast && styles.contrast
      }`}
    >
      {left_icon && <div className={styles.icon}>{left_icon}</div>}
      {children}
      {right_icon && <div className={styles.icon}>{right_icon}</div>}
    </AntButton>
  );
};
