import styles from "./Modal.module.scss";

interface ModalProps {
  children: React.ReactNode;
  is_open: boolean;
}

export const Modal = ({ children, is_open }: ModalProps) => {
  return (
    <div className={`${styles.modal} ${is_open && styles.open}`}>
      {children}
    </div>
  );
};
