import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ImageCardSection from "../../components/organism/ImageCardSection";
import { StartModal } from "../../components/organism/Modal/StartModal/StartModal";
import { routes } from "../../utils/routes";
import { getStaticData } from "../../utils/services";
import styles from "./Stats.module.scss";

export const Stats = () => {
  const { t } = useTranslation("STATS");
  const navigate = useNavigate();
  const [stat, setStat] = useState<StaticData>({
    published: [],
    statistics: { approved: 0, suggestions: 0, topics: 0 },
  });
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getStaticData().then((data) => setStat(data.data));
  }, []);

  return (
    <div className={styles.numeri}>
      <StartModal close_modal={() => setOpenModal(false)} is_open={openModal} />
      <main className={styles.mainContent}>
        <section className={styles.contentHeader}>
          <h3 className={styles.tuttiINumeri}>{t("TITLE")}</h3>
          <div className={styles.statistics}>
            <div className={styles.statistiche}>
              <div className={styles.statisticsData}>
                <b className={styles.emptyData}>
                  {stat.statistics.suggestions}
                </b>
                <h3 className={styles.ideeInnovativeProposte}>
                  {t("CARD_1.TITLE")}
                </h3>
                <div className={styles.numeroseOpportunitImprendit}>
                  {t("CARD_1.DESCRIPTION")}
                </div>
              </div>
            </div>
            <div className={styles.statistiche1}>
              <div className={styles.parent}>
                <b className={styles.b}>{stat.statistics.topics}</b>
                <h3 className={styles.ideeInnovativeProposte1}>
                  {t("CARD_2.TITLE")}
                </h3>
                <div className={styles.numeroseOpportunitImprendit1}>
                  {t("CARD_2.DESCRIPTION")}
                </div>
              </div>
            </div>
            <div className={styles.statistiche2}>
              <div className={styles.group}>
                <b className={styles.b1}>{stat.statistics.approved}</b>
                <h3 className={styles.ideeInnovativeProposte2}>
                  {t("CARD_3.TITLE")}
                </h3>
                <div className={styles.numeroseOpportunitImprendit2}>
                  {t("CARD_3.DESCRIPTION")}
                </div>
              </div>
            </div>
          </div>
        </section>
        <ImageCardSection
          variant="dark"
          cta_text={t("IMAGE_CARD.BUTTON")}
          cta_action={() => {
            navigate(routes.surveyIntro);
          }}
          description={t("IMAGE_CARD.DESCRIPTION")}
          image="/image/stats_image.png"
          title={t("IMAGE_CARD.TITLE")}
          anchor="https://testoip.wallbreakers.it"
        />
      </main>
    </div>
  );
};
