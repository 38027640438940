import Chip from "../../../atoms/Chip";
import styles from "./CardIdea.module.scss";

interface CardIdeaProps {
  className?: string;
  category: string;
  idea_name: string;
  idea_description: string;
  idea_owner?: string;
}

export const CardIdea = ({
  className,
  category,
  idea_name,
  idea_description,
  idea_owner,
}: CardIdeaProps) => {
  return (
    <div className={`${styles.cardIdea} ${className}`}>
      <div className={styles.ideaContainer}>
        <Chip>{category}</Chip>
        <div className={styles.idea}>
          <h2>{idea_name}</h2>
          <p className={styles.ideaDescription}>{idea_description}</p>
        </div>
      </div>
      {idea_owner && (
        <p className={styles.ideaOwner}>
          Idea di: <b>{idea_owner}</b>
        </p>
      )}
    </div>
  );
};
