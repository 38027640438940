import { ArrowDown } from "../Icon/Icon";
import styles from "./Select.module.scss";
import { Select as AntSelect, SelectProps as AntSelectProps } from "antd";

interface SelectProps extends AntSelectProps {
  className?: string;
  label: string;
}

export const Select = ({ className, label, ...props }: SelectProps) => {
  return (
    <div className={`${styles.selectContainer} ${className}`}>
      {label && <div className={styles.label}>{label}</div>}
      <AntSelect
        suffixIcon={<ArrowDown />}
        className={styles.select}
        {...props}
      />
    </div>
  );
};
