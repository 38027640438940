export const routes = {
  home: "/",
  stats: "/statistiche",
  ideas: "/idee_proposte",
  newIdea: "/la_tua_idea",
  thanYouPage: "/grazie_per_la_tua_idea",
  ideaDetail: "/idea",
  error: "/errore",
  surveyIntro: "/intro_questionario",
};
