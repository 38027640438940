import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ErrorPage.module.scss";
import { Error } from "../../components/atoms/Icon/Icon";
import Button from "../../components/atoms/Button";
import { routes } from "../../utils/routes";
import { useState } from "react";
import { postSuggestion } from "../../utils/services";
import { Loader } from "../../components/atoms/Loader/Loader";

export const ErrorPage = () => {
  const { t } = useTranslation("ERROR_PAGE");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const payload = state;

  const [loading, setLoading] = useState(false);

  const retrySubmission = () => {
    setLoading(true);
    if (payload.attachments && payload.answers && payload.recaptchaKey)
      postSuggestion(payload)
        .then((data) => {
          setLoading(false);
          navigate(routes.thanYouPage, { state: data.data.receipt });
        })
        .catch((e) => {
          setLoading(false);
          navigate(routes.error, {
            state: payload,
          });
        });
  };

  return (
    <div className={styles.errorPage}>
      <Loader loading={loading} />
      <div className={styles.content}>
        <div className={styles.text}>
          <Error className={styles.icon} />
          <div className={styles.title}>{t("ERROR")}</div>
          <h1 className={styles.description}>{t("SORRY")}</h1>
          <div className={styles.retry}>{t("RETRY")}</div>
        </div>
        <div className={styles.ctaWrapper}>
          <Button contrast onClick={retrySubmission}>
            {t("CTA.RETRY")}
          </Button>
          <Button
            variant="secondary"
            contrast
            onClick={() => navigate(routes.home)}
          >
            {t("CTA.BACK_HOME")}
          </Button>
        </div>
      </div>
    </div>
  );
};
