import { useTranslation } from "react-i18next";
import styles from "./Benefits.module.scss";

export type ImageTextProps = {
  className?: string;
};

export const ImageText = ({ className = "" }: ImageTextProps) => {
  const { t } = useTranslation("HOME");
  return (
    <section className={[styles.benefits, className].join(" ")}>
      <div className={styles.benefitsListFirstParent}>
        <img
          className={styles.benefitsListFirst}
          loading="lazy"
          alt=""
          src="/image/frame-90@2x.png"
        />
        <div className={styles.benefitsListSecond}>
          <h1 className={styles.crescitaProfessionaleE}>
            {t("IMAGE_TEXT.FIRST.TITLE")}
          </h1>
          <div className={styles.tinextaOffreUn}>
            {t("IMAGE_TEXT.FIRST.DESCRIPTION")}
          </div>
        </div>
      </div>
      <div className={styles.frameParent}>
        <div className={styles.incrementoDellefficienzaECParent}>
          <h1 className={styles.incrementoDellefficienzaE}>
            {t("IMAGE_TEXT.SECOND.TITLE")}
          </h1>
          <div className={styles.tinextaAgisceCome}>
            {t("IMAGE_TEXT.SECOND.DESCRIPTION")}
          </div>
        </div>
        <img
          className={styles.frameChild}
          alt=""
          src="/image/frame-90-1@2x.png"
        />
      </div>
    </section>
  );
};
