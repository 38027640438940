import { Trans, useTranslation } from "react-i18next";
import Input from "../../../atoms/Input";
import Radio from "../../../atoms/Radio";
import styles from "./CardSelection.module.scss";

interface CardSelectionProps {
  className?: string;
  always_anonymous?: boolean;
  active?: boolean;
  show_name_input?: boolean;
  name_value?: string;
  name_onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  surname_value?: string;
  surname_onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CardSelection = ({
  className,
  always_anonymous,
  active,
  show_name_input,
  name_onChange,
  name_value,
  surname_onChange,
  surname_value,
}: CardSelectionProps) => {
  const { t } = useTranslation("CARD_SELECTION");
  return (
    <div className={`${styles.selection} ${className}`}>
      <Radio
        className={`${styles.radioContainer} ${active && styles.active}`}
        value={always_anonymous ? "always_anonymous" : "until_publication"}
      >
        <div className={styles.checkboxContainer}>
          {always_anonymous
            ? t("ALWAIS_ANONIMUS.VALUE")
            : t("UNTIL_PUBLICATION.VALUE")}
          {always_anonymous ? (
            <Trans>
              <p>{t("ALWAIS_ANONIMUS.DESCRIPTION")}</p>
            </Trans>
          ) : (
            <Trans>
              <p>{t("UNTIL_PUBLICATION.DESCRIPTION")}</p>
            </Trans>
          )}
        </div>
      </Radio>
      {show_name_input && (
        <div className={styles.textInputContainer}>
          <Input
            label={t("UNTIL_PUBLICATION.INPUT.NAME.LABEL")}
            placeholder={t("UNTIL_PUBLICATION.INPUT.NAME.PLACEHOLDER")}
            value={name_value}
            onChange={name_onChange}
          />
          <Input
            label={t("UNTIL_PUBLICATION.INPUT.SURNAME.LABEL")}
            placeholder={t("UNTIL_PUBLICATION.INPUT.SURNAME.PLACEHOLDER")}
            value={surname_value}
            onChange={surname_onChange}
          />
        </div>
      )}
    </div>
  );
};
