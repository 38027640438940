import styles from "./Footer.module.scss";
import packageJson from "../../../../package.json";

export type FooterProps = {
  className?: string;
};

export const Footer = ({ className = "" }) => {
  return (
    <footer className={[styles.footerWrapper, className].join(" ")}>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={styles.footerLogo}>
            <img
              className={styles.logoTinextaIcon}
              alt=""
              src="/image/logo_white.svg"
            />
          </div>
          <div className={styles.piva10654631000}>
            P.IVA 10654631000 | Numero REA RM 1247386 | Cap.Soc. deliberato
            €47.207.120,00 sottoscritto e versato €47.207.120,00 | E-mail:
            info@tinexta.com | PEC: tinexta@legalmail.it
          </div>
          <img
            className={styles.image1Icon}
            loading="lazy"
            alt=""
            src="/image/social_icon.svg"
          />
        </div>
        v.{packageJson.version}
      </div>
    </footer>
  );
};
