import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const pageWithHeader = document.getElementsByClassName("pageWithHeader")[0];
    pageWithHeader.scrollTo(0, 0);
  }, [location.pathname]);

  return <></>;
};
