import styles from "./Radio.module.scss";
import {
  Radio as AntRadio,
  RadioProps as AntRadioProps,
} from "antd";

interface RadioProps extends AntRadioProps {
  className?: string;
}

export const Radio = ({
  className,
  ...props
}: RadioProps) => {
  return (
    <div>
      <AntRadio
        className={`${styles.radio} ${className}`}
        {...props}
      />
    </div>
  );
};
