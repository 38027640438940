import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import number1 from "../../assets/icons/numbers/Number-1.svg";
import number2 from "../../assets/icons/numbers/Number-2.svg";
import number3 from "../../assets/icons/numbers/Number-3.svg";
import number4 from "../../assets/icons/numbers/Number-4.svg";
import number5 from "../../assets/icons/numbers/Number-5.svg";
import Button from "../../components/atoms/Button";
import styles from "./SurveyIntro.module.scss";
import { routes } from "../../utils/routes";

export const SurveyIntro = () => {
  const { t } = useTranslation("SURVEY_INTRO");
  const navigate = useNavigate();
  return (
    <div className={styles.surveyIntro}>
      <h1 className={styles.title}>{t("TITLE")}</h1>
      <div className={styles.content}>
        <div className={styles.ideaCriteria}>
          <h2>{t("WHAT_CRITERIA")}</h2>
          <div className={styles.subtitle}>{t("WHAT_COMMITTEE_EVALUATE")}</div>
          <div className={styles.paragraph}>
            <div className={styles.paragraphTitle}>{t("PROPOSAL_QUALITY")}</div>
            <div className={styles.paragraphContent}>
              <Trans>{t("PROPOSAL_QUALITY_TEXT")}</Trans>
            </div>
          </div>
          <div></div>
          <div className={styles.paragraph}>
            <div className={styles.paragraphTitle}>{t("PROPOSAL_IMPACT")}</div>
            <div className={styles.paragraphContent}>
              <Trans>{t("PROPOSAL_IMPACT_TEXT")}</Trans>
            </div>
          </div>
        </div>
        <div className={styles.surveySteps}>
          <h2>{t("WHAT_STEPS")}</h2>
          <div className={styles.subtitle}>{t("STEP_LIST")}</div>
          <div className={styles.stepCard}>
            <div className={styles.cardTitle}>
              <img src={number1} />
              {t("PUBLICATION_METHOD")}
            </div>
            <div className={styles.cardDescription}>
              {t("PUBLICATION_METHOD_TEXT")}
            </div>
          </div>
          <div className={styles.stepCard}>
            <div className={styles.cardTitle}>
              <img src={number2} />
              {t("BRIEF_IDEA")}
            </div>
            <div className={styles.cardDescription}>{t("BRIEF_IDEA_TEXT")}</div>
          </div>
          <div className={styles.stepCard}>
            <div className={styles.cardTitle}>
              <img src={number3} />
              {t("CHALLENGE_AND_IDEAS")}
            </div>
            <div className={styles.cardDescription}>
              {t("CHALLENGE_AND_IDEAS_TEXT")}
            </div>
          </div>
          <div className={styles.stepCard}>
            <div className={styles.cardTitle}>
              <img src={number4} />
              {t("BENEFIT_OF_IDEA")}
            </div>
            <div className={styles.cardDescription}>
              {t("BENEFIT_OF_IDEA_TEXT")}
            </div>
          </div>
          <div className={styles.stepCard}>
            <div className={styles.cardTitle}>
              <img src={number5} />
              {t("RESOURCES_NEEDED")}
            </div>
            <div className={styles.cardDescription}>
              {t("RESOURCES_NEEDED_TEXT")}
            </div>
          </div>
        </div>
      </div>
      <Button
        className={styles.button}
        onClick={() => {
          navigate(routes.newIdea);
        }}
      >
        {t("GET_STARTED")}
      </Button>
    </div>
  );
};
