import styles from "./Navbar.module.scss";
import tinextaLogo from "../../../assets/image/logo.svg";
import Tab from "../../atoms/Tab";
import {
  Canali,
  Close,
  Group,
  Logout,
  Menu,
  Note,
  Notification,
  Settings,
  User,
} from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button";
import { routes } from "../../../utils/routes";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { LanguageSelect } from "../LanguageSelect/LanguageSelect";
import { useTranslation } from "react-i18next";

interface NavbarProps {
  className?: string;
  variant: "admin" | "reciver" | "landing" | "form";
}

export const Navbar = ({ className, variant }: NavbarProps) => {
  const { t } = useTranslation("NAVBAR");
  const param = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div
      className={`${styles.navbar} ${className} ${
        variant === "form" && styles.form
      }`}
    >
      <div className={styles.logo} onClick={() => navigate(routes.home)}>
        <img src={tinextaLogo} />
      </div>
      <div className={styles.tabWrapper}>
        {variant === "admin" && (
          <>
            <Tab active>
              <Group /> {t("TAB.USER")}
            </Tab>
            <Tab>
              <Canali /> {t("TAB.CHANNEL")}
            </Tab>
            <Tab>
              <Notification /> {t("TAB.NOTIFICATION")}
            </Tab>
            <Tab>
              <Note /> Audit log
            </Tab>
          </>
        )}
        {variant === "landing" && (
          <>
            <Tab
              active={pathname === routes.home}
              onClick={() => navigate(routes.home)}
            >
              {t("TAB.DISCOVER")}
            </Tab>
            <Tab
              active={pathname === routes.stats}
              onClick={() => navigate(routes.stats)}
            >
              {t("TAB.STATISTICS")}
            </Tab>
            <Tab
              active={pathname === routes.ideas}
              onClick={() => navigate(routes.ideas)}
            >
              {t("TAB.PROPOSAL")}
            </Tab>
            <LanguageSelect default_language="it" />
          </>
        )}
      </div>
      {(variant === "admin" || variant === "reciver") && (
        <div className={styles.iconWrapper}>
          <Notification />
          <User />
          {variant === "admin" && <Settings />}
          <Logout />
        </div>
      )}
      {variant === "form" && pathname !== routes.thanYouPage && (
        <Button
          className={styles.cancelButton}
          variant="text"
          onClick={() => navigate(routes.home)}
        >
          {param.ideaCode ? t("BUTTON.CLOSE") : t("BUTTON.CANCEL")}
        </Button>
      )}
      {variant !== "form" && (
        <div className={styles.menuIcon}>
          {!menuOpen ? (
            <Menu onClick={() => setMenuOpen(true)} />
          ) : (
            <Close onClick={() => setMenuOpen(false)} />
          )}
        </div>
      )}
      <div
        className={`${styles.mobileMenu} ${!menuOpen && styles.menuClose}`}
      ></div>
      <div
        className={`${styles.mobileTabWrapper} ${!menuOpen && styles.tabClose}`}
      >
        <Tab
          active={pathname === routes.home}
          onClick={() => {
            navigate(routes.home);
            setMenuOpen(false);
          }}
        >
          {t("TAB.DISCOVER")}
        </Tab>
        <Tab
          active={pathname === routes.stats}
          onClick={() => {
            navigate(routes.stats);
            setMenuOpen(false);
          }}
        >
          {t("TAB.STATISTICS")}
        </Tab>
        <Tab
          active={pathname === routes.ideas}
          onClick={() => {
            navigate(routes.ideas);
            setMenuOpen(false);
          }}
        >
          {t("TAB.PROPOSAL")}
        </Tab>
        <LanguageSelect
          className={styles.languageSelectMobile}
          default_language="it"
        />
      </div>
    </div>
  );
};
