import styles from "./Input.module.scss";

interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  className?: string;
  label?: string;
  icon?: React.ReactNode;
  error?: boolean;
  sub_text?: React.ReactNode;
}

export const Input = ({
  className,
  label,
  icon,
  error,
  sub_text,
  ...props
}: InputProps) => {
  return (
    <div
      className={`${styles.inputContainer} ${className} ${
        error && styles.error
      }`}
    >
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.inputWrapper}>
        <input className={styles.input} {...props} />
        {icon && <div className={styles.icon}>{icon}</div>}
      </div>
      {sub_text && <div className={styles.subText}>{sub_text}</div>}
    </div>
  );
};
