import { FunctionComponent, useState } from "react";
import styles from "./ThankYouPage.module.scss";
import { Copy, Success } from "../../components/atoms/Icon/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { SendCodeModal } from "../../components/organism/Modal/SendCodeModal/SendCodeModal";
import { useTranslation } from "react-i18next";

export const ThankYouPage = () => {
  const { t } = useTranslation("THANKYOU_PAGE");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [copiedCode, setCopiedCode] = useState(false);
  const [showCopiedCode, setShowCopiedCode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // TODO: codice proveniente da un chiamata
  const ideaCode = state;
  const copyIdeaCode = () => {
    navigator.clipboard.writeText(ideaCode);
    navigator.clipboard.readText().then((data) => {
      setCopiedCode(true);
      setShowCopiedCode(true);
      setTimeout(() => {
        setCopiedCode(false);
      }, 800);
      setTimeout(() => {
        setShowCopiedCode(false);
      }, 2000);
    });
  };
  return (
    <div className={styles.nuovaSegnalazione}>
      <SendCodeModal
        code={ideaCode}
        close_modal={() => setOpenModal(false)}
        is_open={openModal}
      />
      <main className={styles.content}>
        <section className={styles.successContainer}>
          <div className={styles.successMessage}>
            <div className={styles.successIcon}>
              <Success className={styles.iconsuccess} />
              <h3 className={styles.segnalazioneInviataCorrettam}>
                {t("TITLE")}
              </h3>
            </div>
            <div className={styles.verificationInstructions}>
              <h1 className={styles.copiaESalva}>{t("COPY_CODE")}</h1>
              <div className={styles.inQuestoModo}>{t("PRIVACY")}</div>
              <button className={styles.copyIcon} onClick={copyIdeaCode}>
                <b className={styles.b}>{ideaCode}</b>
                <Copy className={styles.iconcopy} />
              </button>
              {showCopiedCode && (
                <div className={copiedCode ? styles.showCode : styles.hideCode}>
                  {t("CODE_COPIED")}
                </div>
              )}
              <div className={styles.importanteConservaIlContainer}>
                <p className={styles.importanteConservaIlCodice}>
                  <span className={styles.importante}>{t("WARNING")}</span>
                </p>
                <p className={styles.blankLine}>&nbsp;</p>
                <p className={styles.ilCodice}>{t("CODE_EXPLANATION")}</p>
              </div>
            </div>
          </div>
          <div className={styles.cTAButton}>
            <div className={styles.buttonLabel}>
              <button
                className={styles.button}
                onClick={() => navigate(routes.home)}
              >
                <b className={styles.cta}>{t("BUTTON.HOME")}</b>
              </button>
              {/* <div
                className={styles.button1}
                onClick={() => navigate(routes.home)}
              >
                <b className={styles.cta1}>{t("BUTTON.HOME")}</b>
              </div> */}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};
