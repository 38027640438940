import { TextAreaProps as AntTextAreaProps } from "antd/es/input";
import styles from "./Textarea.module.scss";
import { Input, InputProps as AntInputProp } from "antd";

interface TextareaProps extends AntTextAreaProps {
  className?: string;
  label?: string;
  error?: boolean;
}

export const Textarea = ({
  className,
  label,
  error,
  ...props
}: TextareaProps) => {
  return (
    <div
      className={`${styles.inputContainer} ${className} ${
        error && styles.error
      }`}
    >
      {label && <div className={styles.label}>{label}</div>}
      <div className={styles.inputWrapper}>
        {/* <textarea className={styles.input} {...props}></textarea> */}
        <Input.TextArea
          className={styles.input}
          autoSize={{ minRows: 3, maxRows: 6 }}
          {...props}
        />
      </div>
    </div>
  );
};
