import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";
import Button from "../../atoms/Button";
import styles from "./HowWork.module.scss";

export type HowWorkProps = {
  className?: string;
  open_modal: () => void;
};

export const HowWork = ({ className = "", open_modal }: HowWorkProps) => {
  const { t } = useTranslation("HOME");
  const navigate = useNavigate();
  return (
    <section className={[styles.innovationProcess, className].join(" ")}>
      <div className={styles.comeFunzionaParent}>
        <h1 className={styles.comeFunziona}>{t("HOW_WORK.TITLE")}</h1>
        <div className={styles.ilTuoPercorso}>{t("HOW_WORK.SUBTITLE")}</div>
      </div>
      <div className={styles.processSteps}>
        <div className={styles.steps}>
          <div className={styles.step}>
            <div className={styles.stepPlaceholderWrapper}>
              <b className={styles.stepPlaceholder}>1</b>
            </div>
            <div className={styles.laTuaIdea}>{t("HOW_WORK.FIRST_CARD")}</div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepPlaceholderWrapper}>
              <b className={styles.stepPlaceholder}>2</b>
            </div>
            <div className={styles.laTuaIdea}>{t("HOW_WORK.SECOND_CARD")}</div>
          </div>
          <div className={styles.step}>
            <div className={styles.stepPlaceholderWrapper}>
              <b className={styles.stepPlaceholder}>3</b>
            </div>
            <div className={styles.laTuaIdea}>{t("HOW_WORK.THIRD_CARD")}</div>
          </div>
        </div>
        <Button
          className={styles.howWorkBtn}
          contrast
          onClick={() => {
            navigate(routes.surveyIntro);
          }}
        >
          <b>{t("BUTTON.NEW_IDEA")}</b>
        </Button>
      </div>
    </section>
  );
};
