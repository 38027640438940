import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../utils/routes";
import Button from "../../../atoms/Button";
import { Close } from "../../../atoms/Icon/Icon";
import Input from "../../../atoms/Input";
import { Modal } from "../Modal";
import styles from "./StartModal.module.scss";

interface StartModalProps {
  is_open: boolean;
  close_modal: () => void;
}

export const StartModal = ({ is_open, close_modal }: StartModalProps) => {
  const { t } = useTranslation("START_MODAL");
  const navigate = useNavigate();
  const [ideaCode, setIdeaCode] = useState("");

  const newIdea = () => {
    navigate(routes.newIdea);
  };

  const goToIdeaDetail = () => {
    navigate(`${routes.ideaDetail}/${ideaCode}`);
  };

  return (
    <Modal is_open={is_open}>
      <div className={`${styles.startModal}`}>
        <div className={styles.newIdea}>
          <div className={styles.text}>
            <Close className={styles.closeIcon} onClick={close_modal} />
            <h2>{t("NEW_IDEA.TITLE")}</h2>
            <p>{t("NEW_IDEA.DESCRIPTION")}</p>
          </div>
          <Button className={styles.btn} onClick={newIdea}>
            {t("NEW_IDEA.BUTTON")}
          </Button>
        </div>
        <div className={styles.existingIdea}>
          <div className={styles.text}>
            <h2>{t("CHECK_IDEA.TITLE")}</h2>
            <div className={styles.existingIdeaText}>
              {t("CHECK_IDEA.DESCRIPTION")}
            </div>
            {/* <Input
              value={ideaCode}
              onChange={(e) => setIdeaCode(e.target.value)}
              label={t("CHECK_IDEA.INPUT_LABEL")}
              placeholder={t("CHECK_IDEA.INPUT_PLACEHOLDER")}
            /> */}
          </div>
          <a href="https://testoip.wallbreakers.it" target="_blank">
            <Button
              className={styles.btn}
              // onClick={goToIdeaDetail}
            >
              {t("CHECK_IDEA.BUTTON")}
            </Button>
          </a>
        </div>
      </div>
    </Modal>
  );
};
