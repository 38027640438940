import Slider from "react-slick";
import { ArrowLeft, ArrowRight } from "../../atoms/Icon/Icon";
import styles from "./CarouselSection.module.scss";
import CardIdea from "../../molecules/Card/CardIdea";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";
import { ideaCards } from "../../../utils/mock";
import Button from "../../atoms/Button";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export type CarouselSectionProps = {
  className?: string;
};

export const CarouselSection = ({ className = "" }: CarouselSectionProps) => {
  const { t } = useTranslation("HOME");
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const setSlideToShow = () => {
    if (screenWidth < 1600) {
      return 3;
    } else {
      return 5;
    }
  };

  return (
    <section className={[styles.exploreIdeas, className].join(" ")}>
      <h1 className={styles.esploraTutteLe}>{t("CAROUSEL")}</h1>
      <div className={styles.carouselContainer}>
        <Slider
          className={styles.desktopSlider}
          infinite={true}
          // centerMode={true}
          centerPadding="100px"
          slidesToShow={setSlideToShow()}
          slidesToScroll={1}
          speed={700}
          nextArrow={
            <div className={styles.sliderItems}>
              <ArrowRight />
            </div>
          }
          prevArrow={
            <div className={styles.sliderItems4}>
              <ArrowLeft />
            </div>
          }
        >
          {ideaCards.map((i, index) => (
            <CardIdea
              key={index}
              category={i.category}
              idea_description={i.idea_description}
              idea_name={i.idea_name}
              idea_owner={i.idea_owner}
            />
          ))}
        </Slider>
        <div className={styles.mobileSlider}>
          {ideaCards.map((i, index) => (
            <CardIdea
              key={index}
              category={i.category}
              idea_description={i.idea_description}
              idea_name={i.idea_name}
              idea_owner={i.idea_owner}
            />
          ))}
        </div>
      </div>
      <Button
        className={styles.carouselBtn}
        contrast
        onClick={() => navigate(routes.ideas)}
      >
        <b>{t("BUTTON.EXPLORE_IDEAS")}</b>
      </Button>
    </section>
  );
};
