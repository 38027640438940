import { useEffect, useState } from "react";
import styles from "./LanguageSelect.module.scss";
import Select from "../../atoms/Select";
import i18n from "../../../utils/translation/translation";
import flagIta from "../../../assets/icons/flag/ita.svg";
import flagEng from "../../../assets/icons/flag/eng.svg";

interface LanguageSelectProps {
  className?: string;
  default_language: string;
}

export const LanguageSelect = ({
  default_language,
  className,
}: LanguageSelectProps) => {
  const [actualLanguage, setActualLanguage] = useState("it");

  useEffect(() => {
    setActualLanguage(i18n.language);
  }, []);

  return (
    <div className={`${styles.languageSelect} ${className}`}>
      <Select
        label=""
        dropdownAlign={{
          points: ["tl"],
        }}
        dropdownStyle={{ width: "fit-content" }}
        className={styles.select}
        allowClear={false}
        value={actualLanguage}
        onChange={(value) => {
          setActualLanguage(value);
          i18n.changeLanguage(value);
        }}
        options={[
          {
            label: (
              <div className={styles.languageLabel}>
                <div className={styles.languageText}>Italiano (IT)</div>
                <img src={flagIta} />
              </div>
            ),
            value: "it",
          },
          {
            label: (
              <div className={styles.languageLabel}>
                <div className={styles.languageText}>English (EN)</div>
                <img src={flagEng} />
              </div>
            ),
            value: "en",
          },
        ]}
      />
    </div>
  );
};
