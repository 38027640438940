import { useState } from "react";
import Button from "../../atoms/Button";
import { StartModal } from "../Modal/StartModal/StartModal";
import styles from "./Hero.module.scss";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes";

export interface ContentType {
  className?: string;
  open_modal: () => void;
}

export const Hero = ({ className = "", open_modal }: ContentType) => {
  const { t } = useTranslation("HOME");

  const navigate = useNavigate();
  return (
    <section className={[styles.content, className].join(" ")}>
      <div className={styles.heroBanner}>
        <div className={styles.unoSpazioTinextaInCuiPropParent}>
          <Trans>
            <h1 className={styles.unoSpazioTinextaContainer}>
              {t("HERO.TITLE")}
            </h1>

            <div className={styles.dallaConcezioneAllimplementContainer}>
              {t("HERO.SUBTITLE")}
            </div>
          </Trans>
          <div className={styles.heroBannerButtons}>
            <Button
              contrast
              onClick={() => {
                navigate(routes.surveyIntro);
              }}
            >
              {t("BUTTON.NEW_IDEA")}
            </Button>
            <a href="https://testoip.wallbreakers.it" target="_blank">
              <Button variant="secondary" contrast>
                {t("BUTTON.CHECK_IDEA")}
              </Button>
            </a>
          </div>
        </div>
        <img
          className={styles.heroBannerChild}
          loading="lazy"
          alt=""
          src="/image/frame-2608632@2x.png"
        />
        <img
          className={styles.mobileHeroBannerChild}
          loading="lazy"
          alt=""
          src="/image/hero_image_mobile.png"
        />
      </div>
    </section>
  );
};
