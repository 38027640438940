import { Upload as AntUpload, UploadFile } from "antd";
import styles from "./FileUpload.module.scss";
import { v4 as uuidv4 } from "uuid";
import {
  Close,
  Delete,
  Download,
  Error,
  Preview,
  Upload,
} from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button";
import { useState } from "react";
import { postAtachment } from "../../../utils/services";
import { Loader } from "../../atoms/Loader/Loader";

interface FileUploadProps {
  className?: string;
  file_lists?: UploadFile[];
  set_file_list: (value: any) => void;
  label?: string;
  captchaKey: string | null;
}

export const FileUpload = ({
  className,
  file_lists = [],
  set_file_list,
  label,
  captchaKey,
}: FileUploadProps) => {
  const { Dragger } = AntUpload;
  const [loading, setLoading] = useState(false);

  return (
    <div className={`${styles.fileUpload} ${className}`}>
      <Loader loading={loading} />
      <p>{label}</p>
      <div className={styles.uploadWrapper}>
        <Dragger
          disabled={!captchaKey}
          multiple
          beforeUpload={(file, fileList) => {
            set_file_list([...file_lists, ...fileList]);
          }}
          action={(file) => {
            setLoading(true);
            file.uid = uuidv4();
            const id = file.uid;
            const filename = file.name;

            return new Promise(() => {
              postAtachment({ id, filename, file, captchaKey })
                .then(() => {
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  console.log("ERROR", err);
                });
            });
          }}
          onChange={(e) => {
            console.log(e.event);
            set_file_list(e.fileList);
          }}
          itemRender={(origin, file, filelist, actions) => {
            return (
              <div className={styles.uploadContainer}>
                <div
                  className={`${styles.uploadItem} ${
                    file.status === "error" && styles.error
                  }`}
                >
                  <p className={styles.fileName}>{file.name}</p>
                  <div className={styles.actionWrapper}>
                    {file.status === "uploading" && <p>Uploading...</p>}
                    {file.status !== "done" ? (
                      <Close onClick={actions.remove} />
                    ) : file.response === "loaded" ? (
                      <div className={styles.loadedFileIconWrapper}>
                        <Preview
                          className={styles.loadedFileIcon}
                          onClick={actions.preview}
                        />
                        <Download
                          className={styles.loadedFileIcon}
                          onClick={actions.download}
                        />
                      </div>
                    ) : (
                      <Delete
                        className={styles.removeIcon}
                        onClick={actions.remove}
                      />
                    )}
                  </div>
                </div>
                {file.status === "error" && (
                  <p className={styles.subtext}>
                    <Error /> {file.error.message}
                  </p>
                )}
              </div>
            );
          }}
          className={styles.draggerContainer}
          fileList={file_lists}
        >
          <Upload className={styles.uploadIcon} />
          <div>
            <p>
              <b>Drag and drop here to upload</b>
            </p>
            <p>Max limit: 5 MB</p>
          </div>
          <div>
            <p>or</p>
            <Button variant="text">Select file</Button>
          </div>
        </Dragger>
      </div>
    </div>
  );
};
