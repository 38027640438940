import { useState } from "react";
import Button from "../../../atoms/Button";
import { Close } from "../../../atoms/Icon/Icon";
import Input from "../../../atoms/Input";
import { Modal } from "../Modal";
import styles from "./SendCodeModal.module.scss";
import { useTranslation } from "react-i18next";

interface SendCodeModalProps {
  className?: string;
  is_open: boolean;
  code: string;
  close_modal: () => void;
}

export const SendCodeModal = ({
  close_modal,
  is_open,
  className,
  code,
}: SendCodeModalProps) => {
  const { t } = useTranslation("SEND_CODE_MODAL");
  const [email, setEmail] = useState("");
  return (
    <Modal is_open={is_open}>
      <div className={`${styles.sendCodeModal} ${className}`}>
        <div className={styles.modalContent}>
          <Close className={styles.closeIcon} onClick={close_modal} />
          <div>
            <h2>{t("TITLE")}</h2>
            <p>{t("DESCRIPTION")}</p>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              label={t("LABEL")}
              placeholder={t("PLACEHOLDER")}
            />
          </div>
        </div>
        <a
          href={`mailto:${email}?subject=Codice%20proposta&body=La%20tua%20proposta%20%C3%A8%20stata%20registrata%20con%20questo%20codice%20 ${code}`}
        >
          <Button className={styles.sendBtn}>{t("BUTTON")}</Button>
        </a>
      </div>
    </Modal>
  );
};
