import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CarouselSection from "../../components/organism/CarouselSection";
import Hero from "../../components/organism/Hero";
import { HowWork } from "../../components/organism/HowWork/HowWork";
import ImageCardSection from "../../components/organism/ImageCardSection";
import ImageText from "../../components/organism/ImageText";
import { StartModal } from "../../components/organism/Modal/StartModal/StartModal";
import OurObjectives from "../../components/organism/OurObjectives";
import { routes } from "../../utils/routes";
import styles from "./Home.module.scss";

export const Home = () => {
  const { t } = useTranslation("HOME");
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className={styles.home}>
      <StartModal is_open={openModal} close_modal={() => setOpenModal(false)} />
      <Hero open_modal={() => setOpenModal(true)} />
      <OurObjectives open_modal={() => setOpenModal(true)} />
      <HowWork open_modal={() => setOpenModal(true)} />
      <ImageCardSection
        cta_action={() => navigate(routes.stats)}
        cta_text={t("IMAGE_CARD.BUTTON")}
        description={t("IMAGE_CARD.DESCRIPTION")}
        image="/image/frame-2608661@2x.png"
        title={t("IMAGE_CARD.TITLE")}
      />
      <CarouselSection />
      <ImageText />
    </div>
  );
};
