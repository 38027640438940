interface Option {
  label: string;
  value: string;
}

export interface Questions {
  label: string;
  placeholder?: string;
  type: "text" | "textarea" | "select" | "file";
  options?: Option[];
  question: string;
  category: string;
}
export interface Survey {
  title: string;
  subtitle?: string;
  questions: Questions[];
}

export const survey: Survey[] = [
  {
    title: "La tua idea in breve",
    questions: [
      {
        label: "Nome dell’idea",
        placeholder: "Dai un nome alla tua idea",
        type: "text",
        question: "idea_name",
        category: "idea_resume",
      },
      {
        label: "Descrivi brevemente la tua soluzione innovativa",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "idea_description",
        category: "idea_resume",
      },
      {
        label: "A quale categoria appartiene l’idea?",
        placeholder: "Seleziona opzione",
        type: "select",
        options: [
          { label: "FinTech", value: "FinTech" },
          { label: "InsureTech", value: "InsureTech" },
          { label: "AgroTech", value: "AgroTech" },
          { label: "EdTech", value: "EdTech" },
          { label: "DevTech", value: "DevTech" },
        ],
        question: "idea_category",
        category: "idea_resume",
      },
      {
        label: "Qual è il target di riferimento?",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "idea_target",
        category: "idea_resume",
      },
      {
        label: "Quali sono i valori e i benefici principali della soluzione?",
        placeholder: "Inserisci breve descrizione",
        type: "textarea",
        question: "idea_benefit",
        category: "idea_resume",
      },
      {
        label: "Quali sono i potenziali competitor?",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "idea_competitor",
        category: "idea_resume",
      },
    ],
  },
  {
    title: "Sfide e opportunità",
    questions: [
      {
        label:
          "Quali sono le principali sfide che l'azienda sta affrontando al momento?",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "business_challenge",
        category: "challenge_and_opportunity",
      },
      {
        label:
          "Quali opportunità pensi che l'azienda debba affrontare per rimanere competitiva sul mercato?",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "business_opportunity",
        category: "challenge_and_opportunity",
      },
      {
        label:
          "In che modo questa soluzione può risolvere le sfide citate prima?",
        placeholder: "Inserisci breve descrizione",
        type: "textarea",
        question: "challenge_solution",
        category: "challenge_and_opportunity",
      },
      {
        label: "Quali sono gli elementi innovativi della soluzione?",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "innovation_element",
        category: "challenge_and_opportunity",
      },
    ],
  },
  {
    title: "Benefici dell’idea",
    subtitle:
      "Descrivi i potenziali benefici che questa soluzione potrebbe portare:",
    questions: [
      {
        label: "Ai clienti dell’azienda?",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "customer_benefit",
        category: "idea_benefit",
      },
      {
        label: "All'azienda stessa?",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "business_benefit",
        category: "idea_benefit",
      },
      {
        label: "Alla società nel suo complesso?",
        placeholder: "Inserisci breve descrizione",
        type: "textarea",
        question: "whole_business_benefit",
        category: "idea_benefit",
      },
    ],
  },
  {
    title: "Risorse necessarie e prossimi passi",
    subtitle:
      "Quali risorse sono necessarie per implementare questa soluzione?",
    questions: [
      {
        label: "Tecnologiche",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "technologies_resources",
        category: "necessary_resources",
      },
      {
        label: "Umane",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "human_resources",
        category: "necessary_resources",
      },
      {
        label: "Finanziarie",
        placeholder: "Inserisci descrizione",
        type: "textarea",
        question: "financial_resources",
        category: "necessary_resources",
      },
      {
        label: "Altre risorse",
        placeholder: "Approfondisci risorse",
        type: "textarea",
        question: "other_resources",
        category: "necessary_resources",
      },
      {
        label:
          "Quali pensi possano essere i prossimi step per portare avanti questa idea?",
        placeholder: "Inserisci breve descrizione",
        type: "textarea",
        question: "next_step",
        category: "necessary_resources",
      },
      {
        label:
          "Allega eventuali risorse che pensi possano essere utili (pdf, png, jpeg)",
        type: "file",
        question: "idea_attachment",
        category: "necessary_resources",
      },
    ],
  },
];

export const idea = {
  id: "90893ffc-77e4-4dc1-b173-6f3ea3de36c4",
  submit_date: new Date(),
  expired_date: new Date(),
  update_date: new Date(),
  state: "In valutazione",
  anonymous: false,
  name: "Nome",
  surname: "Cognome",
  comments: [
    { comment: "commento", date: new Date(), owner: "Pinco Pallino" },
    { comment: "commento", date: new Date(), owner: "Pinco Pallino" },
    { comment: "commento", date: new Date(), owner: "Pinco Pallino" },
  ],
  idea_resume: {
    idea_name: "Nome Idea",
    idea_description: "Descrizione idea",
    idea_category: "DevTech",
    idea_target: "Target idea",
    idea_benefit: "Benefit idea",
    idea_competitor: "Competitor Idea",
  },
  challenge_and_opportunity: {
    business_challenge: "Sfide",
    business_opportunity: "Opportunità",
    challenge_solution: "Soluzioni",
    innovation_element: "elementi innovativi",
  },
  idea_benefit: {
    customer_benefit: "Benefit Clienti",
    business_benefit: "Benefit Azienda",
    whole_business_benefit: "Benefit società",
  },
  necessary_resources: {
    technologies_resources: "Risorse tecnologiche",
    human_resources: "Risorse umane",
    financial_resources: "Risorse finanziarie",
    other_resources: "altre risorse",
    next_step: "prossimi passi",
  },
};

export const ideaCards = [
  {
    category: "FinTech",
    idea_description:
      "FinNova è una piattaforma rivoluzionaria nel mondo del FinTech, progettata per ridefinire il modo in cui individui e imprese gestiscono le proprie finanze.",
    idea_name: "FinNova e l’innovazione delle finanze",
  },
  {
    category: "EDTech",
    idea_description:
      "Fornisce polizze assicurative a breve termine per coprire specifici periodi di viaggio, offrendo flessibilità agli utenti.",
    idea_name: "Polizze Assicurative Temporanee per Viaggi",
    idea_owner: "Mario Rossi",
  },
  {
    category: "InsureTech",
    idea_description:
      "AssiSure è una piattaforma di InsureTech all'avanguardia progettata per rivoluzionare il settore assicurativo attraverso l'uso di tecnologie avanzate come l'intelligenza artificiale, il machine learning e la blockchain. La nostra missione è semplificare e personalizzare l'esperienza assicurativa.",
    idea_name: "AssiSure Piattaforma di Assicurazioni",
    idea_owner: "Mario Rossi",
  },
  {
    category: "ArgoTech",
    idea_description:
      "AgroNexus è una piattaforma di tecnologia avanzata dedicata all'agricoltura di precisione, progettata per rivoluzionare il modo in cui gli agricoltori gestiscono le loro colture e risorse. Utilizzando una combinazione di IoT (Internet of Things), intelligenza artificiale e big data.",
    idea_name: "ArgoNexus - L’agricoltura del futuro",
  },
  {
    category: "InsureTech",
    idea_description:
      "Fornisce polizze assicurative a breve termine per coprire specifici periodi di viaggio, offrendo flessibilità agli utenti.",
    idea_name: "Polizze Assicurative Temporanee per Viaggi",
  },
  {
    category: "InsureTech",
    idea_description:
      "Fornisce polizze assicurative a breve termine per coprire specifici periodi di viaggio, offrendo flessibilità agli utenti.",
    idea_name: "Polizze Assicurative Temporanee per Viaggi",
    idea_owner: "Mario Rossi",
  },
];
