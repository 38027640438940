import styles from "./Loader.module.scss";

interface LoaderProps {
  loading: boolean;
}
export const Loader = ({ loading }: LoaderProps) => {
  return (
    <div
      className={styles.loading}
      style={{ display: loading ? "flex" : "none" }}
    >
      <img className={styles.loader} src="image/loader/loader.png" />
    </div>
  );
};
