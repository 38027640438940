import { useTranslation } from "react-i18next";
import CardIdea from "../../components/molecules/Card/CardIdea";
import { ideaCards } from "../../utils/mock";
import Content from "./Content/Content";
import styles from "./Ideas.module.scss";
import { useEffect, useState } from "react";
import { getStaticData } from "../../utils/services";

export const Ideas = () => {
  const { t } = useTranslation("IDEAS");

  const [stat, setStat] = useState<StaticData>({
    published: [],
    statistics: { approved: 0, suggestions: 0, topics: 0 },
  });

  useEffect(() => {
    getStaticData().then((data) => {
      setStat(data.data);
    });
  }, []);

  return (
    <div className={styles.archivioIdeeDiBusiness}>
      <Content />
      <section className={styles.ideas}>
        <div className={styles.ideasHeader}>
          <h3 className={styles.esploraEVota}>{t("TITLE")}</h3>
          <div className={styles.ideasGrid}>
            <div className={styles.ideaCards}>
              {stat.published.map((i, index) => (
                <CardIdea
                  key={index}
                  category={i.topic}
                  idea_description={i.description}
                  idea_name={i.title}
                  idea_owner={i.author}
                />
              ))}
            </div>
          </div>
        </div>
        {ideaCards.length > 6 && (
          <button className={styles.button}>
            <img
              className={styles.iconkeyboardArrowLeft}
              alt=""
              src="/iconkeyboard-arrow-left.svg"
            />
            <b className={styles.cta}>{t("BUTTON")}</b>
            <img
              className={styles.iconkeyboardArrowRight}
              alt=""
              src="/iconkeyboard-arrow-right.svg"
            />
          </button>
        )}
      </section>
    </div>
  );
};
